import actions from 'actions';
import {connect} from 'react-redux';
import ManageSchedules from 'Pages/ManageSchedules/ManageSchedules'

const mapStateToProps = (state) => {
  return {
    user:state.current && state.current.user,
    company:state.company && state.company.company,
    languages:state.locales && state.locales.locales
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchLocales:() => {
      return dispatch(actions.fetchLocales());
    },
    saveSchedule:(aScheduleId, aName, aSurveysToAdd, aSurveysToRemove, aGroupsToAdd, aGroupsToRemove, aTriggersToAdd, aTriggersToRemove) => {
      return dispatch(actions.saveSchedule(aScheduleId, aName, aSurveysToAdd, aSurveysToRemove, aGroupsToAdd, aGroupsToRemove, aTriggersToAdd, aTriggersToRemove));
    },
    fetchSchedule:(aScheduleId, aUserId) => {
      return dispatch(actions.fetchSchedule(aScheduleId, aUserId));
    },
    fetchSchedules:(aLimit, aOffset, aSearchString, aUserId, aGroupId, aOnlyRemoved) => {
      return dispatch(actions.fetchSchedules(aLimit, aOffset, aSearchString, aUserId, aGroupId, aOnlyRemoved));
    },
    removeSchedule:(aScheduleId) => {
      return dispatch(actions.removeSchedule(aScheduleId));
    },
    publishSchedule:(aScheduleId) => {
      return dispatch(actions.publishSchedule(aScheduleId));
    },
    saveScheduleTrigger:(aScheduleId, aScheduleTrigger) => {
      return dispatch(actions.saveScheduleTrigger(aScheduleId, aScheduleTrigger));
    },
    createPaymentTrigger:(aTitle, aAmount, aTargetId, aTargetType, aTargetAction, aCurrency, aLimitPerUser, aManualVerificationRequired, aIsEnabled, aStartAt, aEndAt, aTargetExpectedValue, aTargetQuestionKeyword) => {
      return dispatch(actions.createPaymentTrigger(aTitle, aAmount, aTargetId, aTargetType, aTargetAction, aCurrency, aLimitPerUser, aManualVerificationRequired, aIsEnabled, aStartAt, aEndAt, aTargetExpectedValue, aTargetQuestionKeyword));
    },
    fetchScheduleVersion:(aScheduleVersionId) => {
      return dispatch(actions.fetchScheduleVersion(aScheduleVersionId));
    },
    removePaymentTrigger:(aPaymentTriggerId) => {
      return dispatch(actions.removePaymentTrigger(aPaymentTriggerId));
    },
    updatePaymentTrigger:(aId, aTitle, aAmount, aTargetId, aTargetType, aTargetAction, aCurrency, aLimitPerUser, aManualVerificationRequired, aIsEnabled, aStartAt, aEndAt, aTargetExpectedValue, aTargetQuestionKeyword) => {
      return dispatch(actions.updatePaymentTrigger(aId, aTitle, aAmount, aTargetId, aTargetType, aTargetAction, aCurrency, aLimitPerUser, aManualVerificationRequired, aIsEnabled, aStartAt, aEndAt, aTargetExpectedValue, aTargetQuestionKeyword));
    },
    removeScheduleTrigger:(aScheduleId, aScheduleTriggerId) => {
      return dispatch(actions.removeScheduleTrigger(aScheduleId, aScheduleTriggerId));
    },
    fetchPatientsInSchedule:(aLimit, aOffset, aSearchString, aScheduleId) => {
      return dispatch(actions.fetchPatientsInSchedule(aLimit, aOffset, aSearchString, aScheduleId));
    },
    fetchAppAdditionalColumns:(aLimit, aOffset, aSearchString) => {
      return dispatch(actions.fetchAppAdditionalColumns(aLimit, aOffset, aSearchString));
    },
    fetchQuestionsForSurveyById:(aSurveyId) => {
      return dispatch(actions.fetchQuestionsForSurveyById(aSurveyId));
    },
    fetchSurveyVersionCollections:(aLimit, aOffset, aSearchString, aIsArchived, aReferenceRequired, aSearchByField) => {
      return dispatch(actions.fetchSurveyVersionCollections(aLimit, aOffset, aSearchString, aIsArchived, aReferenceRequired, aSearchByField));
    },
    fetchScheduleVersionsForSchedule:(aScheduleId, aLimit, aOffset) => {
      return dispatch(actions.fetchScheduleVersionsForSchedule(aScheduleId, aLimit, aOffset));
    },
    fetchQuestionsForSurveyByReference:(aReference) => {
      return dispatch(actions.fetchQuestionsForSurveyByReference(aReference));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageSchedules)
