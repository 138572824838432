import React, { useEffect, useState } from 'react';
import './SignUpLogin.scss';
import Loading from "../../Custom UI/Loading/Loading";
import {Translation} from "react-i18next";
import parsePhoneNumber from 'libphonenumber-js'
import i18n from "../../i18n";
import {setDefaultLocale} from "react-datepicker";
import moment from "moment/moment";

const Login = ({user, login, loadUser}) => {
  
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const [hasTriedAutoLoad, setHasTriedAutoLoad] = useState(false);
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [countryCode, setCountryCode] = useState('+1');
  const [contactNumber, setContactNumber] = useState('');
  const [currentUser, setCurrentUser] = useState(user);
  const [locale, setLocale] = useState('en');
  
  const language = new URLSearchParams(window.location.search).get('locale');
  const signInToken = new URLSearchParams(window.location.search).get('sign_in_token');

  const submitLogin = (aEvent) => {
    aEvent.preventDefault();
    aEvent.stopPropagation();
    setLoading(true);
    
    login(email, password, locale).then(newResponse => {
      setLoading(false);
    });
  };

  const verifyOtp = (aEvent) => {
    aEvent.preventDefault();
    aEvent.stopPropagation();
    setLoading(true);
    let phoneNumber = countryCode + contactNumber;

    if(signInToken && signInToken.length > 0){
      if(contactNumber){
        login(email, password, locale, otp, phoneNumber, signInToken).then(newResponse => {
          setLoading(false);
        });
      }
      else{
        login(email, password, locale, otp, phoneNumber, signInToken).then(newResponse => {
          setLoading(false);
        });
      }
    }
    else{
      if(contactNumber){
        login(email, password, locale, otp, phoneNumber).then(newResponse => {
          setLoading(false);
        });
      }
      else{
        login(email, password, locale, otp).then(newResponse => {
          setLoading(false);
        });
      }
    }
  };

  const submitPhoneNumber = (aEvent) => {
    aEvent.preventDefault();
    aEvent.stopPropagation();
    let phoneNumber = countryCode + contactNumber;
    setLoading(true);
    if(signInToken && signInToken.length > 0){
      login(email, password, locale, otp, phoneNumber, signInToken).then(newResponse => {
        setLoading(false);
      });
    }
    else{
      login(email, password, locale, null, phoneNumber).then(newResponse => {
        setLoading(false);
      });
    }
  };

  const handleChange = (aEvent) => {
    let value = aEvent.target.value;
    setContactNumber(value.replace(/\D/g,''))
  }

  const backToLogin = () => {
    setEmail('');
    setPassword('');
    setContactNumber('');
    setOtp('');
    setCurrentUser({});
    setLocale('en');
  };

  const isValidPhoneNumber = () => {
    const phoneNumber = parsePhoneNumber('Phone: ' + countryCode + '-' + contactNumber);
    let returnValue = false;

    if(phoneNumber){
      returnValue = phoneNumber.isValid();
    }
    return returnValue;
  };
  
  useEffect(() => {
    loadUser().then(() => setLoading(false));
  }, []);
  
  useEffect(() => {
    if(language && ['de', 'en', 'es', 'fr', 'it'].includes(language)){
      setLocale(language);
      i18n.changeLanguage(language).then((newResult) => {
        setDefaultLocale(language);
        moment.locale(language)
      });
    }
  }, [language]);

  useEffect(() => {
    setCurrentUser(user)
  }, [user]);
  
  // useEffect(() => {
  //   let localeString = navigator.language;
  //   setLocale(localeString.slice(0,2));
  // }, [locale]);

  const requiredFieldsFilled = () => {
    return email && password
  };
  
  const countryCodeOptionsArray = [
    {value:'+1', label:'USA and Canada +1'},
    {value:'+49', label:'Germany +49'},
    {value:'+39', label:'Italy +39'},
    {value:'+34', label:'Spain +34'},
    {value:'+44', label:'United Kingdom +44'},
    {value:'+358', label:'Finland +358'},
    {value:'+33', label:'France +33'},
    {value:'+52', label:'Mexico +52'},
    {value:'+57', label:'Colombia +57'},
    {value:'+27', label:'South Africa +27'},
    {value:'+30', label:'Greece +30'},
    {value:'+31', label:'Netherlands +31'},
    {value:'+32', label:'Belgium +32'},
    {value:'+63', label:'Philippines +63'},
    {value:'+350', label:'Gibraltar +350'},
    {value:'+351', label:'Portugal +351'},
    {value:'+352', label:'Luxembourg +352'},
    {value:'+353', label:'Ireland +353'},
    {value:'+354', label:'Iceland +354'},
    {value:'+355', label:'Albania +355'},
    {value:'+356', label:'Malta +356'},
    {value:'+357', label:'Cyprus +357'},
    {value:'+359', label:'Bulgaria +359'},
    {value:'+36', label:'Hungary +36'},
    {value:'+370', label:'Lithuania +37'},
    {value:'+371', label:'Latvia +371'},
    {value:'+372', label:'Estonia +372'},
    {value:'+373', label:'Moldova +373'},
    {value:'+374', label:'Armenia +374'},
    {value:'+375', label:'Belarus +375'},
    {value:'+376', label:'Andorra +376'},
    {value:'+377', label:'Monaco +377'},
    {value:'+378', label:'San Marino +378'},
    {value:'+380', label:'Ukraine and Crimea +380'},
    {value:'+381', label:'Serbia +381'},
    {value:'+382', label:'Montenegro +382'},
    {value:'+385', label:'Croatia +385'},
    {value:'+386', label:'Slovenia +386'},
    {value:'+387', label:'Bosnia and Herzegovina +387'},
    {value:'+389', label:'Macedonia +389'},
    {value:'+40', label:'Romania +40'},
    {value:'+41', label:'Switzerland +42'},
    {value:'+420', label:'Czech Republic +420'},
    {value:'+421', label:'Slovakia +421'},
    {value:'+43', label:'Austria +43'},
    {value:'+45', label:'Denmark +45'},
    {value:'+46', label:'Sweden +46'},
    {value:'+47', label:'Norway +47'},
    {value:'+48', label:'Poland +48'},
    {value:'+7', label:'Russia +7'},
    {value:'+91', label:'India +91'},
    {value:'+55', label:'Brazil +55'}
    ];

  const responseFromServer = currentUser && currentUser.errors ? currentUser.errors && currentUser.errors[0] && currentUser.errors[0].title : '';
  const twoStepVerification = currentUser && currentUser.two_factor_authentication && currentUser.two_factor_authentication[0].otp_required;
  const twoStepVerificationError = currentUser &&  currentUser.two_factor_authentication && currentUser.two_factor_authentication[0].error;
  const primaryContactNumber = currentUser && currentUser.two_factor_authentication && currentUser.two_factor_authentication[0].primary_contact_number;
  const missingContactNumber = currentUser && currentUser && currentUser.two_factor_authentication && currentUser.two_factor_authentication[0].primary_contact_number === '';
  const errorMessage = twoStepVerificationError ? twoStepVerificationError : responseFromServer === '' ? '' : '*' + responseFromServer;

  if(!loading && !hasTriedAutoLoad && signInToken && signInToken.length > 0 && responseFromServer.length === 0){
    setLoading(true);
    setHasTriedAutoLoad(true);
    let phoneNumber;
    if(contactNumber) {
      phoneNumber = countryCode + contactNumber;
    }

    login(email, password, locale, otp, phoneNumber, signInToken).then((newResult) => {
      setLoading(false);
    });
  }

  return (
    <div className='login-sign-up-container'>
      <div className='login-signup-container-inner'>
        <div className="login-sign-up-logo-head">
          <img src="https://s3.amazonaws.com/hs-app-images/HSC_Logo_75_percent_opacity.png" alt='HSC Logo'/>
          <span>
            <Translation>{ (t, { i18n }) => t('HEALTH_STORYLINES_CREATOR') }</Translation>
          </span>
        </div>
        <p className='login-heading'>
          <Translation>{ (t, { i18n }) => t('WELCOME_BACK') }</Translation>
          <br/><Translation>{ (t, { i18n }) => t('LOGIN_TO_HSC') }</Translation>&trade;
        </p>
        
        {!twoStepVerification &&
          <form className="floating-form">
            <div className="floating-label login-input-width">
              <input className="floating-input " value={email} name="email" onChange={(e) => setEmail(e.target.value)} type="email" placeholder=" "/>
              <label>
                <Translation>{ (t, { i18n }) => t('EMAIL') }</Translation>
              </label>
            </div>
            <div className="floating-label login-input-width">
              <input className="floating-input" value={password} name="password" onChange={(e) => setPassword(e.target.value)} type="password" placeholder=" "/>
              <label>
                <Translation>{ (t, { i18n }) => t('PASSWORD') }</Translation>
              </label>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', marginRight: '20px'}}>
              <a href="../forgot" id="forgot-password" className="forgot-password-button">
                <Translation>{ (t, { i18n }) => t('FORGOT_PASSWORD_QUESTION') }</Translation>
              </a>
              
              {loading ?
                <Loading className="login-spinner"
                         showLabel={false}/>
                :
                <button className="login-submit-button clickable"
                        onClick={submitLogin}
                        disabled={!requiredFieldsFilled()}
                        style={{opacity: requiredFieldsFilled() ? 1 : 0.5}}>
                  <Translation>{ (t, { i18n }) => t('LOGIN') }</Translation>
                </button>
              }
            </div>
          </form>
        }

        {twoStepVerification && !missingContactNumber &&
          <form className="floating-form">
            <div className="login-add-phone-number-text">
              <Translation>{ (t, { i18n }) => t('VERIFY_USING_OTP') }</Translation> {primaryContactNumber}
            </div>
            
            <div className="floating-label login-input-width">
              <input className="floating-input " value={otp} onChange={(e) => setOtp(e.target.value)} type="text"/>
              <label>
                <Translation>{ (t, { i18n }) => t('VERIFICATION_CODE') }</Translation>
              </label>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', marginRight: '20px'}}>
              <a href="#" id="back-to-login" onClick={backToLogin} className="back-button">
                <Translation>{ (t, { i18n }) => t('BACK_TO_LOGIN') }</Translation>
              </a>
              {loading ?
                <Loading className="login-spinner"
                         showLabel={false}/>
                :
                <button className="login-submit-button clickable" onClick={verifyOtp} disabled={!otp}
                        style={{opacity: otp ? 1 : 0.5}}>
                  <Translation>{(t, {i18n}) => t('VERIFY')}</Translation>
                </button>
              }
            </div>
          </form>
        }

        {twoStepVerification && missingContactNumber &&
          <form className="floating-form">
            <div className="login-add-phone-number-text">
              <Translation>{ (t, { i18n }) => t('ADD_PHONE') }</Translation>
            </div>
            <div className="login-add-phone-number-container">
              <div className="floating-label login-add-phone-number-select">
                <select className="floating-input floating-input-phone"
                        value={countryCode}
                        onChange={(aEvent) => setCountryCode(aEvent.target.value)}>
                  {countryCodeOptionsArray.map((aOption, index) => {
                    return <option className="country-code-option" key={aOption.value} value={aOption.value}>{aOption.label}</option>
                  })
                  }
                </select>
                <label>
                  <Translation>{ (t, { i18n }) => t('COUNTRY_CODE') }</Translation>
                </label>
              </div>
              
              <div className="floating-label login-input-width">
                <input className="floating-input login-add-phone-number-input"
                       value={contactNumber}
                       name="contact"
                       onChange={(e) => handleChange(e)}
                       type="tel"
                       placeholder=" "/>
                <label>
                  <Translation>{ (t, { i18n }) => t('PHONE_NUMBER') }</Translation>
                </label>
              </div>
            </div>
            
            <div style={{display: 'flex', justifyContent: 'space-between', marginRight: '20px'}}>
              <a href="#" id="back-to-login" onClick={backToLogin} className="back-button">
                <Translation>{ (t, { i18n }) => t('BACK_TO_LOGIN') }</Translation>
              </a>
  
              {loading ?
                <Loading className="login-spinner"
                         showLabel={false}/>
                :
                <button className="login-submit-button clickable" onClick={submitPhoneNumber}
                        disabled={!isValidPhoneNumber()} style={{opacity: isValidPhoneNumber() ? 1 : 0.5}}>
                  <Translation>{(t, {i18n}) => t('SUBMIT_PROPER_CAPITALIZED')}</Translation>
                </button>
              }
            </div>
          </form>
        }
        
        {/* <div className='sign-up-msg'><span>Don't have an account? <a className="login-signup-link" href="/signup">Sign up</a></span></div> */}
  
        {errorMessage ?
          <ul className="error-msg">
            {errorMessage}
          </ul>
          :
          null
        }
      </div>
    </div>
  );
}
export default Login
